<template>
  <div class="login">
    <div class="login-center">
      <img class="login-center-img" src="../assets/bj.png" />
    </div>
    <div class="login-center-right">
      <!-- 密码输入框 -->
      <div class="login-center-right-div">
        <div class="login-center-right-div-block">
          <img class="block-img" src="../assets/hehuor.png" />
        </div>

        <div class="login-center-right-div-title">合伙人后台管理</div>

        <div class="login-center-right-div-display">
          <div
            class="left"
            v-for="(item, index) in 2"
            :key="index"
            :class="{ leftActive: index == loginIndex }"
            @click="leftClick(index)"
          >
            {{ index == 0 ? '账号密码登录' : '验证码登录' }}

            <div class="left-lint" v-if="index == loginIndex"></div>
          </div>
        </div>

        <div v-if="loginIndex == 1">
          <div
            class="login-center-right-div-center-block"
            style="margin-top: 20px"
          >
            <img
              class="login-center-right-div-center-block-left"
              src="../assets/account.png"
            />
            <input
              class="login-center-right-div-center-block-input"
              type="text"
              v-model="account"
              placeholder="请输入手机号"
            />
          </div>

          <div
            class="login-center-right-div-center-block"
            style="margin-top: 10px"
          >
            <img
              class="login-center-right-div-center-block-left"
              src="../assets/password.png"
            />
            <input
              class="login-center-right-div-center-block-input"
              v-model="password"
              placeholder="请输入短信验证码"
              @keyup.enter="registerBtn"
            />

            <div class="input-div" @click="verificationClick">
              {{ count == 0 ? '发送验证码' : count + '秒' }}
            </div>
          </div>
        </div>

        <div v-else>
          <div
            class="login-center-right-div-center-block"
            style="margin-top: 20px"
          >
            <img
              class="login-center-right-div-center-block-left"
              src="../assets/account.png"
            />
            <input
              class="login-center-right-div-center-block-input"
              type="text"
              v-model="account"
              placeholder="请输入登录账号"
            />
          </div>

          <div
            class="login-center-right-div-center-block"
            style="margin-top: 10px"
          >
            <img
              class="login-center-right-div-center-block-left"
              src="../assets/password.png"
            />
            <input
              class="login-center-right-div-center-block-input"
              v-model="password"
              placeholder="请输入密码"
              @keyup.enter="registerBtn"
              type="password"
            />
          </div>
        </div>

        <div
          class="login-center-right-div-center-block-error"
          v-if="verify == true"
        >
          {{ messgae }}
        </div>

        <div
          class="register-block"
          :style="{ 'margin-top': verify == true ? '23px' : '45px' }"
          @click="registerBtn"
        >
          登 录
        </div>
      </div>
    </div>
  </div>
</template>
      <!-- <img class="center-right-logo" src="../assets/logo.png" alt="" />
      <div class="center-right-title">合伙人后台管理</div>
      <div class="center-block">
        <img class="center-block-left" src="../assets/account.png" />
        <input
          class="center-block-input"
          type="text"
          v-model="account"
          placeholder="输入登录账号"
        />
      </div>
      <div class="center-block" style="margin-top: 10px">
        <img class="center-block-left" src="../assets/password.png" />
        <input
          class="center-block-input"
          type="password"
          v-model="password"
          placeholder="输入密码"
          @keyup.enter="registerBtn"
        />
      </div>

      <div class="center-block-error" v-if="verify == true">
        {{ messgae }}
      </div>
      <div
        class="register-block"
        :style="{ 'margin-top': verify == true ? '23px' : '45px' }"
        @click="registerBtn"
      >
        登 录
      </div> -->


<script>
import { userLogin, postcode } from '../api/login'

import { getWalletInfo } from '../api/pay'

import { verify } from '../utils/verify'

import { md5 } from '../utils/md5'

export default {
  data() {
    return {
      // 账号
      account: '',
      // git 密码
      password: '',
      // 校验是否错误
      verify: false,
      // 错误消息提示
      messgae: '',
      loginIndex: 0,
      //------
      count: 0,
      timer: null,
    }
  },
  methods: {
    verificationClick() {
      if (this.count != 0) {
        return
      }

      if (this.account == '') {
        this.$message('请先输入手机号！')
        return
      }

      //倒计时时间
      const TIME_COUNT = 60
      if (!this.timer) {
        this.count = TIME_COUNT
        this.timer = setInterval(() => {
          if (this.count > 0 && this.count <= TIME_COUNT) {
            this.count--
          } else {
            this.show = true
            clearInterval(this.timer)
            this.timer = null
          }
        }, 1000)

        this.postcodeMet()
      }
    },
    postcodeMet() {
      let params = {
        phone: this.account,
      }
      postcode(params).then((res) => {})
    },
    leftClick(index) {
      this.account = ''
      this.password = ''
      this.messgae = ''

      this.loginIndex = index
    },
    registerBtn() {
      if (this.account != '' || this.password != '') {
        let params = {}

        if (this.loginIndex == 0) {
          params = {
            phone: this.account,
            password: this.password,
          }
        } else {
          params = {
            phone: this.account,
            loginType: 'CODE',
            code: this.password,
          }
        }

        userLogin(params).then((res) => {
            console.log('res11111111')
          console.log('res', res)

          if (res.data.code == 200) {
            localStorage.setItem('userInfo', JSON.stringify(res.data.content))
            // // 存储对应的对象 虚假token 进行判断
            localStorage.setItem('token', res.data.content.accessToken)
            this.getWalletInfoMet()
            // // 登陆成功 假设这里是后台返回的 token

            this.account = ''
            this.password = ''
          } else {
            this.verify = true
            this.messgae = res.data.msg
            this.$message(res.data.msg)
          }
        })
      } else {
        this.verify = true

        this.messgae =
          this.loginIndex == 0
            ? '账号或密码有误, 请重新输入'
            : '账号或验证码有误, 请重新输入'
      }
    },


    getWalletInfoMet() {
      let params = {}

      getWalletInfo(params).then((res) => {
        if (res.data.code == 200) {
            localStorage.setItem('walletInfo', JSON.stringify(res.data.content))

            this.$router.push({ path: this.redirect || '/' })
        }
      })
    }
  },
}
</script>

<style lang="less" scoped>
:focus-visible {
  outline: -webkit-focus-ring-color auto 0px;
}

.login {
  width: 100vw;
  position: relative;
  .login-center {
    position: relative;
    display: flex;
    width: 100%;
    .login-center-img {
      width: 100%;
      height: 100vh;
      position: absolute;
    }
  }
  .login-center-right {
    position: absolute;
    z-index: 11;
    right: 357px;
    top: 120px;

    .login-center-right-div {
      width: 370px;
      height: 500px;
      background: #ffffff;
      box-shadow: 0px 2px 20px 0px rgba(71, 75, 75, 0.1);
      border-radius: 10px;
      padding-top: 79px;
      box-sizing: border-box;
      position: relative;
      z-index: 12;

      .login-center-right-div-block {
        width: 100%;
        height: 67px;
        display: flex;
        justify-content: center;

        .block-img {
          width: 136px;
          height: 67px;
        }
      }

      .login-center-right-div-title {
        height: 30px;
        font-size: 22px;
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;
        color: #555555;
        line-height: 30px;
        text-align: center;
        margin-top: 9px;
      }

      .login-center-right-div-display {
        height: 40px;
        display: flex;
        width: 100%;
        align-items: center;
        margin-top: 20px;
        padding: 0 10px;
        box-sizing: border-box;

        .left {
          cursor: pointer;
          width: 50%;
          height: 100%;
          font-family: PingFang-SC, PingFang-SC;
          font-weight: bold;
          font-size: 16px;
          color: #666666;
          display: flex;
          align-items: center;
          justify-content: center;
          position: relative;
        }

        .leftActive {
          color: #11d653;
        }

        .left-lint {
          position: absolute;
          bottom: 0;
          width: 53.5px;
          height: 3px;
          background: #11d653;
        }
      }

      .login-center-right-div-center-block {
        width: 280px;
        height: 40px;
        background: #f6f6f6;
        border-radius: 20px;
        border: 1px solid #dddddd;
        margin: 0 auto;
        display: flex;
        align-items: center;
        position: relative;

        .login-center-right-div-center-block-left {
          margin-left: 15px;
        }

        .input-div {
          padding: 5px 15px;
          box-sizing: border-box;
          border-radius: 5px;
          color: #fff;
          font-size: 14px;
          position: absolute;
          right: 10px;
          background: #07c160;
          cursor: pointer;
        }

        /deep/ input {
          border: 0px;
          background: #f6f6f6;
          width: 200px;
        }

        .login-center-right-div-center-block-input {
          margin-left: 15px;
        }

        input::placeholder {
          font-size: 14px;
          font-family: PingFangSC-Regular, PingFang SC;
          font-weight: 400;
          color: #cccccc;
        }
      }

      .login-center-right-div-center-block-error {
        height: 20px;
        font-size: 14px;
        font-family: PingFangSC-Semibold, PingFang SC;
        font-weight: 600;
        color: #ed6d1f;
        line-height: 20px;
        margin-top: 2px;
        display: flex;
        justify-content: center;
      }

      .register-block {
        width: 280px;
        height: 50px;
        background: linear-gradient(135deg, #10b52d 0%, #38df55 100%);
        border-radius: 24px;
        margin: 0 auto;
        font-size: 20px;
        font-family: PingFangSC-Semibold, PingFang SC;
        font-weight: 600;
        color: #ffffff;
        line-height: 50px;
        cursor: pointer;
        text-align: center;
      }
    }
  }
}
</style>
